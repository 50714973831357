import { corpSimCardIcc } from "@telia/b2x-rest-client";
import { t } from "./locale";

type Feedback = {
  message: string;
  isError: boolean;
};

export class ValidateIcc {
  prefix: string;
  specLength: number;

  constructor(prefix: string, specLength: number) {
    this.prefix = prefix;
    this.specLength = specLength;
  }

  async verifyIcc(icc: string): Promise<Feedback> {
    const slicedPrefix = icc?.slice(0, 6);
    const measuredLength = icc?.length;

    if (slicedPrefix !== this.prefix) {
      return {
        message: t("ICC_INVALID_FORMAT"),
        isError: true,
      };
    }
    if (measuredLength !== this.specLength) {
      return {
        message: t("TELIASIMCARDNUMBER_INVALID_LENGTH"),
        isError: true,
      };
    }
    if (!this.testLuhn(icc)) {
      return {
        message: t("LUHNSTRING_INVALID_CHECKSUM"),
        isError: true,
      };
    }
    const isIccAlreadyInUse = await this.checkIfIccExists(icc);
    if (isIccAlreadyInUse) {
      return {
        message: t("ICC_ALREADY_IN_USE"),
        isError: true,
      };
    }

    return {
      message: t("SIMCARD_NUMBER_VALID"),
      isError: false,
    };
  }

  testLuhn(t: string): boolean {
    const numbers = t?.split("").map((x) => {
      return parseInt(x);
    });

    let sum = numbers?.slice(-1)[0];
    const numDigits = numbers?.length;
    const parity = numDigits % 2;

    numbers?.forEach((digit, index) => {
      let currentDigit = digit;
      if (index < numDigits - 1) {
        if (index % 2 === parity) {
          currentDigit = digit * 2;
        }
        if (currentDigit > 9) {
          currentDigit -= 9;
        }
        sum += currentDigit;
      }
    });
    return sum % 10 === 0;
  }

  async checkIfIccExists(icc: string): Promise<string | boolean> {
    try {
      const response = await corpSimCardIcc.SimCardControllerService.validateIccUsingGet(icc);
      if (response.ok) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      return true;
    }
  }
}
